<template>
    <b-nav-item :href="menu.uri" class="peekage-nav-item font f-s-14 l-h-24 f-w-700" :class="{
                    'only-device': menu.onlyDevice,
                    'active': isActive
                }"
                ref="activeNavItem"
    >
        {{ menu.name }}
    </b-nav-item>
</template>

<script>
export default {
    props: {
        menu: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        isActive() {
            return this.$route.fullPath.toLowerCase() === this.menu.uri.toLowerCase();
        },
    },
    mounted() {
        this.anchorHandler();
    },
    methods: {
        anchorHandler () {
            if (process.client) {
                if (!this.menu.anchored) return;

                if (this.isActive) {
                    this.$refs.activeNavItem.classList.add('active');
                }
                try {
                    const step1 = this.menu.uri.replace('/#', '');
                    const step2 = step1.replace('/', '');
                    const element = document.querySelector(`#${step2}`);

                    if (element) {
                        const offsetTop = element.offsetTop;
                        window.addEventListener('scroll', (e) => {
                            const offsetScroll = window.scrollY;

                            if ((offsetScroll) >= offsetTop) {
                                this.$refs.activeNavItem.classList.add('active');
                            // this.$scrollTo(`#${step2}`);
                            } else if (offsetScroll < offsetTop) {
                                this.$refs.activeNavItem.classList.remove('active');
                            }
                        });
                    }
                } catch (error) {
                    // console.log(error);
                }
            }
        },
    },
};
</script>

<style lang="scss">
    // .peekage-nav-item {
    //     position: relative;
    //     &::after {
    //         content: "";
    //         width: 4px;
    //         height: 8px;
    //         background-color: #000;
    //         position: absolute;
    //         left: 0px;
    //         top: 32px;
    //         border-radius: 2px;
    //     }

    //     &.active {
    //         color: #FF4F66;
    //         .nav-link {
    //             color: #FF4F66 !important;
    //         }
    //         &::after {
    //             background-color: #FF4F66 !important;
    //         }
    //     }
    // }
</style>
