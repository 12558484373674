<template>
    <header class="navbar-container" :class="{ scrolled }">
        <container size="lg" class="">
            <b-navbar toggleable="lg" class="p-0">
                <b-navbar-brand hreflang="en-ca" href="/" class="m-l-small-20 p-0">
                    <img :src="$image('logo-default.svg')" alt="Peekage Logo" width="129" height="72">
                </b-navbar-brand>

                <b-navbar-toggle ref="navbar-toggle" target="nav-collapse" class="m-r-small-20" @click="backdropHandler" />

                <b-collapse id="nav-collapse" is-nav class="p-x-small-20 nav-menus">
                    <!-- Right aligned nav items -->
                    <b-navbar-nav class="m-auto" align="center">
                        <template v-for="(menu, i) in menus">
                            <component :is="menu.component" :key="i" :menu="menu" />
                        </template>
                    </b-navbar-nav>
                </b-collapse>

                <div class="only-desktop header-talk-to-expert">
                    <nuxt-link hreflang="en-ca" to="/contact?talk-to-an-expert=true" style="letter-spacing: -0.3px;" class="d-flex btn sm primary font f-s-14 l-h18 f-w-700">
                        Talk to an expert
                    </nuxt-link>
                </div>
            </b-navbar>
        </container>
    </header>
</template>

<script>
import PeekageNavGroup from '~/components/layout/header/peekage-nav-item-group';
import PeekageNavItem from '~/components/layout/header/peekage-nav-item';

const menus = [
    {
        name: 'Why Peekage ?',
        component: 'PeekageNavItem',
        uri: '/#WhyPeekage',
        anchored: true,
    },
    {
        name: 'Solutions',
        component: 'PeekageNavGroup',
        sub: [
            {
                name: 'Product Sampling',
                subtitle: 'Boost sales through targeted digital product sampling program',
                uri: '/product-sampling-program',
                anchored: false,
            },
            {
                name: 'Consumer Insights',
                subtitle: 'Actionable Consumer Insights, Faster Than Ever!',
                uri: '/consumer-insights-company',
                anchored: false,
            },
        ],
    },
    {
        name: 'Consumer App',
        component: 'PeekageNavItem',
        uri: '/consumer-apps',
        anchored: false,
    },
    {
        name: 'Resources',
        component: 'PeekageNavGroup',
        sub: [
            {
                name: 'Blog',
                subtitle: '',
                uri: 'https://peekage.com/blog',
                anchored: false,
            },
            {
                name: 'FAQ',
                subtitle: '',
                uri: '/faq',
                anchored: false,
            },
            {
                name: 'Our Community',
                subtitle: '',
                uri: '/natural-shoppers-community',
                anchored: false,
            },
        ],
    },
    {
        name: 'Talk to an expert',
        component: 'PeekageNavItem',
        uri: '/contact?talk-to-an-expert=true',
        anchored: false,
        onlyDevice: true,
    },
];
export default {
    components: {
        PeekageNavGroup,
        PeekageNavItem,
    },
    data() {
        return {
            menus: [...menus],
            scrolled: false,
            backdrop: false,

        };
    },
    beforeMount() {
        window.addEventListener('scroll', this.handleHeaderStyles);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleHeaderStyles);
    },
    mounted() {
        this.handleHeaderStyles();
    },
    methods: {
        handleHeaderStyles () {
            if (process.client) {
                this.scrolled = window.scrollY > 0;
            } else {
                this.scrolled = true;
            }
        },
        backdropHandler() {
            this.$emit('backdrop-handler');
        },
    },
};
</script>

<style lang="scss" >
    .header-only-logo {
        .header-talk-to-expert, .nav-menus {
            display: none !important;
        }
    }
</style>

<style lang="scss" scoped>

    .navbar-container {
        background-color: #fff;
        position: fixed;
        top: 0px;
        width: 100%;
        z-index: 1000;
        &.scrolled {
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
        }
        .navbar-toggler {
            border: unset;
        }

        @media (min-width: 992px) {
            position: sticky;
        }
    }
</style>
