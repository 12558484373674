<template>
    <b-nav-item-dropdown no-caret :text="menu.name" class="peekage-nav-item" :class="{
        'only-device': menu.onlyDevice,
        'active': isActive
    }"
    >
        <template #button-content>
            <span class="font f-s-14 l-h-24 f-w-700">{{ menu.name }}</span>
        </template>

        <b-dropdown-item v-for="(sub, i) in menu.sub" :key="i" :href="sub.uri">
            <strong class="font f-s-16 l-h-24 f-w-bold m-b-4">{{ sub.name }}</strong>
            <p class="font f-s-12 l-h-20 f-w-normal m-0" style="white-space: normal;">
                {{ sub.subtitle }}
            </p>
        </b-dropdown-item>
    </b-nav-item-dropdown>
</template>

<script>
export default {
    props: {
        menu: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        isActive() {
            const path = this.menu.sub.map(e => e.uri);
            return path.includes(this.$route.fullPath);
        },
    },
};
</script>

<style lang="scss">
    .peekage-nav-item {
        min-height: 57px;
        padding: 16px 16px;
        border-bottom: 1px solid #e0e0e0;
        &:last-child {
            border: unset;
        }

        &.active {
            color: #FF4F66;
            .nav-link {
                color: #FF4F66 !important;
            }
            &::after {
                background-color: #FF4F66 !important;
            }
        }

        &.show, &.active {
            position: relative;
            &::after {
                content: "";
                width: 4px;
                height: 8px;
                background-color: #000;
                position: absolute;
                left: 0px;
                top: 32px;
                border-radius: 2px;
            }
        }

        .dropdown {
            &-menu {
                padding: 0px !important;
                border: unset;

                li {
                    margin-right: 12px;
                    &:last-child {
                        margin-right: 0px;
                    }
                }
            }

            &-item {
                border-radius: 8px;
                padding: 8px 12px;
                width: 188px;
                &:active {
                    background-color: #000;
                }
            }
        }

        @media only screen and (min-width: 992px) {
            /* For desktop: */
            padding: 24px 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 72px;
            border-bottom: unset;
            position: relative;
            &:hover, &.show, &.active {
                &::after {
                    content: "";
                    width: 8px;
                    height: 4px;
                    background-color: #000;
                    position: absolute;
                    bottom: 0px;
                    left: unset;
                    top: unset;
                    border-radius: 2px;
                }
            }

            .dropdown {
                &-menu {
                    padding: 20px 32px !important;
                    &.show {
                        display: flex;
                        left: 50%;
                        transform: translateX(-50%);
                        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.12);
                    }
                }
            }
        }
    }
</style>
